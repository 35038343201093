<template>
  <div
    class="menu-header"
    @click="handleClick"
  >
    <img
      src="../img/menu-header.png"
      alt=""
    >
  </div>
</template>

<script>
  export default {
    methods: {
      handleClick() {
        window.open('https://mp.weixin.qq.com/s/8IhEzkbvqIXi0EcoEZ70QA')
      }
    }
  }
</script>

<style lang="scss" scoped>
.menu-header{
  cursor: pointer;
  width: 100%;
  height: 78px;
  display: none;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 1440px) {
  height: 58px;
}
</style>