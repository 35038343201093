<template>
  <div class="flow">
    <PageHeader />
    <div class="header-top">
      <Header />
      <div class="header-text">
        <div class="header-title">
          语音红包
        </div>
        <div class="header-sign">
          行业首创 · 用户主导性互动变现广告新模式
        </div>
      </div>
    </div>
    <div class="main">
      <!-- 广告投放 -->
      <div class="item">
        <div class="advantage">
          <div class="title">
            <img
              src="@/img/vioce/advantage-title1.png"
              alt=""
            >
          </div>
          <div class="title-secend">
            <img
              src="@/img/vioce/advantage-title-2.png"
              alt=""
            >
          </div>
          <div class="advantage-list">
            <div class="advantage-item">
              <div class="header">
                <div class="header-img">
                  <img
                    src="@/img/vioce/advantage-img-1.png"
                    alt=""
                  >
                </div>
                <div class="header-text-main">
                  优质流量
                </div>
                <div class="header-line" />
                <div class="other-text">
                  针对媒体流量，多维度防作弊
                  机型，筛选最优质媒体流量，
                  保证流量转化真实有效
                </div>
              </div>
            </div>
            <div class="advantage-item">
              <div class="header">
                <div class="header-img">
                  <img
                    src="@/img/vioce/advantage-img-2.png"
                    alt=""
                  >
                </div>
                <div class="header-text-main">
                  声音传递价值
                </div>
                <div class="header-line" />
                <div class="other-text">
                  自主朗读广告语，潜移默化传
                  递价值，精准触达高价值目标
                  受众，提升有效转化
                </div>
              </div>
            </div>
            <div class="advantage-item">
              <div class="header">
                <div class="header-img">
                  <img
                    src="@/img/vioce/advantage-img-3.png"
                    alt=""
                  >
                </div>
                <div class="header-text-main">
                  定制化推广
                </div>
                <div class="header-line" />
                <div class="other-text">
                  针对广告属性，定制推广方案
                  定向投放设置、用户属性设置
                  高效获取用户，实现闭环服
                  务，助力
                </div>
              </div>
            </div>
            <div class="advantage-item">
              <div class="header">
                <div class="header-img">
                  <img
                    src="@/img/vioce/advantage-img-4.png"
                    alt=""
                  >
                </div>
                <div class="header-text-main">
                  专业服务
                </div>
                <div class="header-line" />
                <div class="other-text">
                  7*24全天候专业人员服务
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 媒体投放 -->
      <div class="item">
        <div class="advantage">
          <div class="title">
            <img
              src="@/img/vioce/advantage-title2.png"
              alt=""
            >
          </div>
          <div class="title-secend">
            <img
              src="@/img/vioce/advantage-title-2-new.png"
              alt=""
            >
          </div>
          <div class="advantage-list">
            <div class="advantage-item">
              <div class="header">
                <div class="header-img">
                  <img
                    src="@/img/vioce/advantage-img-1-new.png"
                    alt=""
                  >
                </div>
                <div class="header-text-main">
                  流量价值新模式
                </div>
                <div class="header-line" />
                <div class="other-text">
                  国内首创语音广告形式，语音
                  播报广告核心价值内容、用户
                  自动朗读广告口令领取媒体激
                  励奖励、试玩形式新颖，用户
                  体验度新。
                </div>
              </div>
            </div>
            <div class="advantage-item">
              <div class="header">
                <div class="header-img">
                  <img
                    src="@/img/vioce/advantage-img-2-new.png"
                    alt=""
                  >
                </div>
                <div class="header-text-main">
                  海量广告
                </div>
                <div class="header-line" />
                <div class="other-text">
                  快手、什么值得买、米米捕鱼
                  伊对、QQ阅读海量知名广告主
                  精准投放，投其所好。
                </div>
              </div>
            </div>
            <div class="advantage-item">
              <div class="header">
                <div class="header-img">
                  <img
                    src="@/img/vioce/advantage-img-3-new.png"
                    alt=""
                  >
                </div>
                <div class="header-text-main">
                  扩充变现收益
                </div>
                <div class="header-line" />
                <div class="other-text">
                  对接形式简单，可对接性强；
                  广告模式较轻，适合大部分变现
                  场景程序化推广，用户自主抉择
                  下载体验
                </div>
              </div>
            </div>
            <div class="advantage-item">
              <div class="header">
                <div class="header-img">
                  <img
                    src="@/img/vioce/advantage-img-4-new.png"
                    alt=""
                  >
                </div>
                <div class="header-text-main">
                  提高产品价值
                </div>
                <div class="header-line" />
                <div class="other-text">
                  合适场景，有助于优化媒体
                  自身产品留存及用户体验深度
                  及留存（如：小说语音广告替
                  代付费章节；游戏拆语音广告
                  免费复活灯）
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 语音红包模式 -->
      <div class="item">
        <div class="extension">
          <div class="title">
            <img
              src="@/img/vioce/extension-title-new.png"
              alt=""
            >
          </div>
          <div class="extension-main">
            <div class="phone-wrap">
              <div class="model">
                <div class="block">
                  <el-carousel
                    height="523px"
                    @change="chengePhone"
                  >
                    <el-carousel-item>
                      <div class="phone-item">
                        <div class="phone-item-img">
                          <img
                            src="@/img/vioce/phone-1.png"
                            alt=""
                          >
                        </div>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item>
                      <div class="phone-item">
                        <div class="phone-item-img">
                          <img
                            src="@/img/vioce/phone-2-new.png"
                            alt=""
                          >
                        </div>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item>
                      <div class="phone-item">
                        <div class="phone-item-img">
                          <img
                            src="@/img/vioce/phone-3-new.png"
                            alt=""
                          >
                        </div>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item>
                      <div class="phone-item">
                        <div class="phone-item-img">
                          <img
                            src="@/img/vioce/phone-4-new.png"
                            alt=""
                          >
                        </div>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <transition name="fade">
                  <div
                    v-show="phoneText===0"
                    class="phone-item-other"
                  >
                    <div class="phone-item-text">
                      根据场景创建激励型广告推广位
                    </div>
                  </div>
                </transition>
                <transition name="fade">
                  <div
                    v-show="phoneText===1"
                    class="phone-item-other phone-item-other-secend"
                  >
                    <div class="phone-item-text">
                      根据用户属性及广告设置
                      推送广告！
                    </div>
                  </div>
                </transition>
                <transition name="fade">
                  <div
                    v-show="phoneText===2"
                    class="phone-item-other phone-item-other-three"
                  >
                    <div class="phone-item-text phone-item-text-three">
                      自主朗读，潜移默化理解
                      广告价值！
                    </div>
                  </div>
                </transition>
                <transition name="fade">
                  <div
                    v-show="phoneText===3"
                    class="phone-item-other phone-item-other-fourth"
                  >
                    <div class="phone-item-text phone-item-text-three">
                      多样化广告落地页，进一
                      步促进用户转化!
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 点击开始推广 -->
      <!-- <div
        class="handle-extension"
        @click="extension()"
      >
        <div class="btn">
          点击开始推广
        </div>
      </div> -->
    </div>
    <!-- <div class="join-box">
      <div class="join-title">
        流量价值  零成本  高收益
      </div>
      <div class="join-desc">
        新量象流量价值平台是团队将公司旗下产品核心技术及广告数据库整合成为一个开放引擎，可以便捷接入流量方，轻松为流量主实现流量高收益变现。
      </div>
      <div
        class="join-btn"
        @click="handleClickDialog"
      >
        让流量价值
      </div>
    </div> -->
    <div class="footer-content">
      <PageFooter />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import { mapState } from 'vuex'

export default {
  components: {
    Header,
    PageHeader,
    PageFooter
  },
  data() {
    return {
      phoneText: 0 //语音红包手机模型切换展示
    }
  },
  computed: {
    ...mapState(['showDialog']),
  },
  methods: {
    // 语音红包手机轮播图片
    chengePhone(e) {
      this.phoneText = e
    },
    handleClickDialog() {
      if (!this.showDialog) {
        this.$store.commit('changeDialog', true)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-top{
  position: relative;
  width: 100%;
  height: 285px;
  background: url('../img/yyhb-banner.png') no-repeat;
  background-size: cover;
  margin-bottom: 50px;
}
.header-text{
  position: absolute;
  top: 166px;
  left: 260px;
}
.header-title{
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  color: #fff;
  font-family: 'fzqk';
}
.header-sign{
  font-size: 24px;
  line-height: 24px;
  color:rgba(255,255,255,0.75)
}
.content{
  padding: 0 300px;
}
.footer-content{
  padding: 0 250px;
}
.section{
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 140px;
  &-info{
    .info-num{
      font-size:50px;
      height: 50px;
      line-height: 50px;
      color:rgba(254,233,203,1);
    }
    .info-title{
      font-size:56px;
      //font-family:'fzqk';
      height: 55px;
      line-height: 55px;
      color:rgba(102,102,102,1);
      margin-bottom: 22px;
      margin-top: -25px;
    }
    .info-line{
      width: 45px;
      height: 8px;
      margin-bottom: 25px;
    }
    .info-text{
      display: flex;
      margin-bottom: 35px;
      align-items: flex-start;
      &-title{
        color: #FF8400;
        font-size: 18px;
        line-height: 18px;
        margin-top: 15px;
      }
      &-desc{
        font-size:18px;
        color:#666666;
        line-height:48px;
        max-width: 456px;
      }
    }
    .info-icon-title{
      font-size:18px;
      color:rgba(255,132,0,1);
      line-height:18px;
      margin-bottom: 30px;
    }
    .info-icon-list{
      display: flex;
      align-items: center;
    }
    .info-icon-item{
      margin-right: 40px;
      font-size:14px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color:rgba(102,102,102,1);
      img{
        width: 56px;
        height: 56px;
        margin-bottom: 7px;
      }
    }
  }
  &-imgs{
    display: flex;
    img{
      width: 225px;
      height: 400px;
    }
    img:nth-child(2){
      margin: -30px -18px 0;
    }
  }
}
.join-box{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height:480px;
  background:linear-gradient(-45deg,rgba(255,96,0,1),rgba(255,144,0,1));
  margin-bottom: 60px;
  box-sizing: border-box;
  padding-top: 83px;
  .join-title{
    height:50px;
    line-height: 50px;
    font-size:50px;
    font-family: 'fzqk';
    font-weight:bold;
    color:rgba(255,255,255,1);
    margin-bottom: 45px;
  }
  .join-desc{
    font-size:22px;
    color:rgba(255,255,255,1);
    line-height:48px;
    margin-bottom: 47px;
    text-align: center;
  }
  .join-btn{
    width:300px;
    height:68px;
    background:rgba(255,255,255,0.15);
    border:2px solid rgba(255,255,255,1);
    border-radius:34px;
    font-size:30px;
    //font-family:'fzqk';
    font-weight:bold;
    text-align: center;
    line-height: 68px;
    cursor: pointer;
    color:rgba(255,255,255,1);
  }
}
@media screen and (max-width: 1440px) {
  .header{
    height: 285px;
  }
  .header-title{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-text{
    top: 125px;
    left: 195px;
  }
  .header-sign{
    font-size: 15px;
    line-height: 15px;
  }
  .content{
    padding: 0 225px;
  }
  .footer-content{
    padding: 0 225px;
  }
  .section{
    padding-top: 77px;
    margin-bottom: 103px;
    &-info{
      .info-num{
        font-size:38px;
        height: 38px;
        line-height: 38px;
      }
      .info-title{
        font-size:42px;
        height: 42px;
        line-height: 42px;
        margin-bottom: 16px;
        margin-top: -20px;
      }
      .info-line{
        width: 34px;
        height: 5px;
        margin-bottom: 19px;
      }
      .info-text{
        margin-bottom: 24px;
        &-title{
          font-size: 14px;
          line-height: 14px;
          margin-top: 11px;
        }
        &-desc{
          max-width: 353px;
          font-size:14px;
          line-height:36px;
        }
      }
      .info-icon-title{
        font-size:14px;
        line-height:14px;
        margin-bottom:23px;
      }
      .info-icon-item{
        margin-right: 30px;
        font-size:12px;
        line-height: 12px;
        img{
          width: 42px;
          height: 42px;
          margin-bottom: 7px;
        }
      }
    }
    &-imgs{
      display: flex;
      img{
        width: 170px;
        height: 302px;
      }
      img:nth-child(2){
        margin: -22px -13px 0;
      }
    }
  }
  .join-box{
    height:360px;
    margin-bottom: 44px;
    padding-top: 62px;
    .join-title{
      height:38px;
      line-height: 38px;
      font-size:38px;
      margin-bottom: 33px;
    }
    .join-desc{
      font-size:16px;
      line-height:36px;
    }
    .join-btn{
      width:222px;
      height:50px;
      background:rgba(255,255,255,0.15);
      border:2px solid rgba(255,255,255,1);
      border-radius:25px;
      font-size:22px;
      line-height: 50px;
    }
  }

}
@media screen and (min-width: 1445px) {
.header-top{
    height: 400px;
}
.header-title{
    font-size: 40px;
    line-height: 36px;
    margin-bottom: 30px;
}
.header-sign{
    font-size: 20px;
  }
}
.main{
        display: flex;
        flex-direction: column;
        .item{
            display: flex;
            flex-direction: column;
            padding: 10px 30px;
            margin-bottom: 20px;
            .flow-path{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 36.45px;
                .flow-path-img{
                    width: 1035px;
                    height: 137px;
                }
                .flow-path-list{
                    display: flex;
                    flex-direction: row;
                    width: 1035px;
                    text-align: center;
                    .flow-path-item{
                        margin-left: 55.93px;
                        .number{
                            font-size: 14px;
                            //font-family: PingFang SC;
                            font-weight: bold;
                            color: #249CEB;
                            line-height: 27px;
                        }
                        .text{
                            font-size: 18px;
                            //font-family: PingFang SC;
                            font-weight: 400;
                            color: #000000;
                            line-height: 27px;
                        }
                    }
                    .secend{
                        margin-left: 195px;
                    }
                    .three{
                        margin-left: 204px;
                    }
                }
            }
            .advantage{
                display: flex;
                flex-direction: column;
                align-items: center;
                 margin-top: 30px;
                .title{
                    width: 350px;
                    height: 27px;
                }
                .title-secend{
                    width: 541px;
                    height: 30px;
                    margin-top: 55px;
                }
                .advantage-list{
                    width: 883px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 53px;
                    .advantage-item{
                        display: flex;
                        flex-direction: column;
                        .header{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 170px;
                            .header-img{
                                width: 96px;
                                height: 96px;
                            }
                            .header-text-main{
                                font-size: 23px;
                                //font-family: FZQingKeBenYueSongS-R-GB;
                                font-weight: 400;
                                color: #FF7800;
                                line-height: 47px;
                            }
                            .header-line{
                                width: 44px;
                                height: 3px;
                                background: #FFDDB8;
                                border-radius: 1px;
                            }
                        }
                        .other-text{
                            margin-top: 17px;
                            width: 170px;
                            font-size: 12px;
                            //font-family: PingFang SC;
                            font-weight: 400;
                            color: #777777;
                            line-height: 21px;
                            text-align: center;
                        }
                    }
                }
            }
            .extension{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 100px;
                .title{
                    width: 418px;
                    height: 27px;
                }
            }
        }
        .handle-extension{
            width: 100%;
            height: 98px;
            background:rgba(0, 0, 0, .4);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .btn{
                width: 403px;
                height: 65px;
                background: linear-gradient(90deg, #FF7200, #FFA200);
                border-radius: 32px;
                font-size: 25px;
                //font-family: FZQingKeBenYueSongS-R-GB;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 65px;
                text-align: center;
                cursor: pointer;
            }
        }
}
.extension-main {
    .phone-wrap{
                display: flex;
                justify-content: center;
                margin-bottom:92px;
                margin-top: 68px;
                .model{
                    width: 306px;
                    height: 523px;
                    position: relative;
                    .phone-item{
                        width: 306px;
                        height: 523px;
                        .phone-item-img{
                            width: 306px;
                            height: 523px;
                        }
                    }
                    .phone-item-other{
                            position: absolute;
                            top: 106.48px;
                            left: -247px;
                            width: 321px;
                            height: 95.6px;
                            background-image: url('~@/img/vioce/phone-text-1.png');
                            background-repeat: no-repeat;
                            background-size: cover;
                            z-index: 2;
                        .phone-item-text{
                            margin-top: 29px;
                            margin-left: 29px;
                            width: 171px;
                            font-size: 14px;
                            //font-family: FZQingKeBenYueSongS-R-GB;
                            font-weight: bold;
                            color: #FF7800;
                            line-height: 23px;
                        }
                        .phone-item-text-three{
                            margin-left: 134px;
                        }
                    }
                    .phone-item-other-secend{
                        top: 442px;
                        left: -244px;
                        width: 321px;
                        height: 94px;
                        background-image: url('~@/img/vioce/phone-text-2.png');
                    }
                    .phone-item-other-three{
                        top: 21px;
                        left: 242px;
                        width: 313px;
                        height: 188px;
                        background-image: url('~@/img/vioce/phone-text-3.png');
                    }
                    .phone-item-other-fourth{
                        top: 360px;
                        left: 236px;
                        width: 315px;
                        height: 95px;
                        background-image: url('~@/img/vioce/phone-text-4.png');
                    }
                }
            }
}
img{
          width: 100%;
          height: 100%;
}
.fade-enter-active, .fade-leave-active{
            transition:opacity 1s
}
.fade-enter, .fade-leave-to{
    opacity: 0;
}

@media screen and (min-width: 1496px){
  .advantage-list{
      width: 1200px!important;
  }
}
</style>
