<template>
  <div class="page-footer">
    <div class="item">
      <div class="item-name">
        商务洽谈
      </div>
      <img
        src="../img/about-icon.png"
        alt=""
        class="item-icon"
      >
      <div class="item-contact">
        <Contact />
      </div>
    </div>
    <div class="item phone">
      <img
        src="../img/about-icon.png"
        alt=""
        class="item-icon"
      >
      <div
        class="item-p"
        style="margin-bottom: 30px;"
      >
        <div class="item-p-title">
          联系电话
        </div>
        <div class="item-p-desc">
          0571-89808775
        </div>
      </div>
      <!-- <div
        class="item-p"
        style="margin-bottom: 30px;"
      >
        <div class="item-p-title">
          注册地址
        </div>
        <div class="item-p-desc">
          浙江省丽水市莲都区城北街368号绿谷信息产业园1号楼2楼201-1
        </div>
      </div> -->
      <div
        class="item-p"
        style="margin-bottom: 50px;"
      >
        <div class="item-p-title">
          公司地址
        </div>
        <div class="item-p-desc">
          浙江省杭州市余杭区未来科技城五常街道联创街153号丽水数字大厦B幢9层
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-logo">
        <img
          src="../img/logo-bottom.png"
          alt=""
        >
      </div>
      <div class="footer-sign">
        <div class="footer-sign-line" />
        <div class="footer-sign-desc">
          © 2019  杭州新量象网络科技有限公司  版权所有 |  <a
            href="https://beian.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer"
            v-if="beian"
          >浙ICP备19045824号-1</a>
          <a
            href="https://beian.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer"
            v-else
          >浙ICP备2021034612号</a>
        </div>
        <div class="footer-sign-line" />
      </div>
    </div>
  </div>
</template>

<script>
import Contact from '@/components/Contact'
export default {
  components: {
    Contact
  },
  data() {
    return{
      beian:true //备案
    }
  },

  mounted(){
    this.getDomain()
  },
  methods:{
    //备案更改
    getDomain(){
      let domain = document.domain;//先获取当前访问的全域名
      //let domain = 'http://www.xinliangxiang.cn';//先获取当前访问的全域名
      let domain2 = domain.substring(domain.indexOf('.')+1);//截取第一个点的位置来获取根域名,这样只能获取二级域名的根域名，如果直接是不带www的域名访问，那么就会直接获取域名的后缀
      if(domain2.indexOf('.')==-1){
          //可以通过判断来二次获取点的位置
          console.log(document.domain)
      }else{
        console.log(domain2)
        if(domain2=='xinliangxiang.cn'){
          this.beian=false
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item{
  &.phone{
    margin-top: 60px;
  }
  &-name{
    font-size:30px;
    // font-family:'fzqk';
    color:rgba(102,102,102,1);
    line-height:30px;
    margin-bottom: 28px;
  }
  &-icon{
    width: 45px;
    height: 8px;
    margin-bottom: 31px;
  }
  &-desc{
    font-size:18px;
    color:rgba(102,102,102,1);
    line-height:36px;
    margin-bottom: 90px;
  }
  &-p{
    display: flex;
    align-items: center;
    font-size:18px;
    color:rgba(153,153,153,1);
    line-height:18px;
    &-title{
      margin-right: 30px;
      color: #FF7800FF;
    }
  }
}
.footer-logo{
  width: 153px;
  height: 36px;
  margin: 0 auto 36px;
  img{
    width: 100%;
    height: 100%;
  }
}
.footer-sign{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:12px;
  color:rgba(186,186,186,1);
  line-height: 12px;
  margin-bottom: 50px;
  a{
    color:rgba(186,186,186,1);
    text-decoration: none;
  }
  &-desc{
    margin: 0 30px;
  }
  &-line{
    width: 164px;
    height:1px;
    background:rgba(221,221,221,1);
  }
}
@media screen and (max-width: 1440px) {
  .item{
    &.phone{
      margin-top: 45px;
    }
    &-name{
      font-size:22px;
      line-height:22px;
      margin-bottom: 24px;
    }
    &-icon{
      width: 32px;
      height: 6px;
      margin-bottom: 23px;
    }
    &-desc{
      font-size:13px;
      line-height:27px;
      margin-bottom: 73px;
    }
    &-p{
      font-size:13px;
      &-title{
        margin-right: 20px;
      }
    }
  }
  .footer-logo{
    width: 114px;
    height: 27px;
    margin-bottom: 20px;
  }
  .footer-sign{
    display: flex;
    align-items: center;
    font-size:12px;
    color:rgba(186,186,186,1);
    line-height: 12px;
    margin-bottom: 37px;
    &-desc{
      margin: 0 20px;
    }
    &-line{
      width: 120px;
      height:1px;
      background:rgba(221,221,221,1);
    }
  }
}

</style>
